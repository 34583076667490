var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "规则", visible: _vm.dialogVisible, width: "1200px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规则名称",
                prop: "name",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "400px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入规则名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "条件规则",
                prop: "condition_group",
                "label-width": _vm.formLabelWidth,
                required: "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "rule-edit" },
                [
                  _c("TqRuleGeneration", {
                    attrs: { "condition-group": _vm.conditionGroupTree },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "分配对象",
                prop: "return_value.members",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "400px" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "组织树暂未完成，先使用下拉框！",
                        placement: "top-end",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            multiple: "",
                            placeholder: "添加人员",
                          },
                          model: {
                            value: _vm.form.return_value.members,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.return_value, "members", $$v)
                            },
                            expression: "form.return_value.members",
                          },
                        },
                        _vm._l(_vm.userList, function (opt) {
                          return _c("el-option", {
                            key: opt.id,
                            attrs: { label: opt.name, value: opt.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "分配方式",
                prop: "return_value.assign_type",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "ROUND_ROBIN" },
                  model: {
                    value: _vm.form.return_value.assign_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.return_value, "assign_type", $$v)
                    },
                    expression: "form.return_value.assign_type",
                  },
                },
                [_vm._v("按顺序轮流分配")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "备注",
                prop: "memo",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "400px" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入活动备注" },
                    model: {
                      value: _vm.form.memo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "memo", $$v)
                      },
                      expression: "form.memo",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }